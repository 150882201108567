<template>
  <div class="container">
    <div class="swiper-img">
      <van-swipe @change="onChange" :stop-propagation="false">
        <van-swipe-item v-for="(item,index) in imgList" :key="index">
          <div class="img">
            <!-- <img :src="item" alt @click="lookAll" /> -->
            <van-image fit="cover" :src="item.value" @click="lookAlls(0,0)" />
            <!-- <van-image  fit="scale-down" :src="item" width="100vw" height="211px" /> -->
            <!-- <van-image  fit="none" :src="item" width="100vw" height="211px" /> -->
            <!-- <van-image  fit="cover" :src="item" width="100vw" height="211px" /> -->
          </div>
        </van-swipe-item>
        <template #indicator>
          <div class="custom-indicator" style="color:#ffffff">{{ current + 1 }}/{{imgList.length}}</div>
        </template>
      </van-swipe>
    </div>
    <div class="main">
      <!-- 价格 -->
      <div class="title">
        <ul class="title-n">
          <li>
            <div>
              <span class="m-tip">￥</span>
              <span class="m-num">{{titleInfo.carPrice}}</span>
              <span class="m-let">万元</span>
            </div>
            <div class="reset">重置成本 ￥{{titleInfo.price}}万元</div>
          </li>
          <li class="timer">
            <div class="timer-t">{{titleInfo.evaTime|dateFormatMonths}}</div>
            <div class="day" @click="showTime=true">
              <div>评估基准日</div>
              <img src="../../assets/report/crazy.png" alt />
            </div>
          </li>
        </ul>
      </div>
      <!-- 车辆标题 -->
      <div class="carmsg">
        <div class="carName">{{titleInfo.vehicleName}}</div>
        <div class="series" @click="showSeries=true">
          <div>调整系数</div>
          <img src="../../assets/report/crazy.png" alt />
        </div>
        <ul class="changeNumber">
          <li>
            <div>{{titleInfo.marketForce}}</div>
            <div>区域市场影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{titleInfo.brandForce}}</div>
            <div>品牌影响</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{titleInfo.usedForce}}</div>
            <div>使用状况影响</div>
          </li>
        </ul>
      </div>
      <!-- 评估机构 -->
      <div class="mechanism">
        <div class="m-title">
          <div>{{titleInfo.evaOrg}}</div>
          <div>
            评报字(
            <span v-if="titleInfo.year">{{titleInfo.year}}</span>
            <span v-if="!titleInfo.year">-</span>年
            )第
            <span v-if="titleInfo.num">{{titleInfo.num}}</span>
            <span v-if="!titleInfo.num">-</span> 号
          </div>
        </div>
        <!-- 内容 -->
        <ul class="m-content">
          <li>
            <div class="m-tips">
              <div>委</div>
              <div>托</div>
              <div>方 :</div>
            </div>
            <div class="m-tips-content">{{titleInfo.clientName}}</div>
          </li>
          <li>
            <div class="m-tips">
              <div>评</div>
              <div>估</div>
              <div>目</div>
              <div>的 :</div>
            </div>
            <div>{{titleInfo.aim}}</div>
          </li>
        </ul>
        <div class="m-before" v-if="mechanism">绪言：</div>
        <div class="m-be-c" v-if="mechanism">
          <span>{{titleInfo.evaOrg}}</span>
          接受客户委托，根据《中华人民共和国资产评估法》、《二手车流通管理办法》和《商用车鉴定评估技术规范》的规定，本着客观、独立、公正、科学的原则，对车辆识别代号为
          <span>{{msgInfo.vin}}</span> 牌号为
          <span>{{titleInfo.plateNo}}</span>的车辆进行了鉴定。本机构鉴定评估人员按照必要的程序，对委托鉴定评估的车辆进行了实地查勘与市场调查，并对其在
          <span>{{titleInfo.evaTime|dateFormatMonth}}</span>所表现的市场价值作出了公允反映。
        </div>
        <div class="m-before" v-if="mechanism">声明：</div>
        <ul class="m-be-tip" v-if="mechanism">
          <li>（1）本鉴定评估机构对该鉴定评估报告承担法律责任；</li>
          <li>（2）本报告所提供的车辆评估价值为评估结论有效期内的价值；</li>
          <li>（3）该鉴定评估报告的使用权归委托方所有，其鉴定评估结论仅供委托方为本项目鉴定评估目的使用，不适用于其他目的，否则本鉴定评估机构不承担相应法律责任；因使用本报告不当而产生的任何后果与签署本报告书的鉴定评估人员无关；</li>
          <li>（4）本鉴定评估机构承诺，未经委托方许可，不将本报告的内容向他人提供或公开，否则本鉴定评估机构将承担相应法律责任。</li>
          <li>委托方承诺所提供车辆及手续均为真实合法，本机构对车辆及相关手续的来源真实性和合法性不承担法律责任。</li>
        </ul>
        <div class="showMechanism" v-if="!mechanism" @click="mechanism=true">
          <span>查看更多</span>
          <van-icon name="arrow-down" />
        </div>
        <div class="showMechanism" v-if="mechanism" @click="mechanism=false">
          <span>收起</span>
          <van-icon name="arrow-up" />
        </div>
      </div>
      <!-- 评估师 -->
      <div class="appraiser" v-if="!titleInfo.isExam" @click="jumpEav">
        <div class="logo">
          <!-- <div class="a-img"> -->
          <!-- <img :src="titleInfo.avatarUrl" alt /> -->
          <van-image fit="cover" :src="titleInfo.avatarUrl" />
          <!-- </div> -->
          <div class="a-name">{{titleInfo.evaPersonName}}</div>
        </div>
        <div class="a-title-img">
          <div class="a-title" v-if="titleInfo.levelId == 1">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 初级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 2">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 中级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 3">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 高级</div>
          </div>
          <img class="a-link" src="../../assets/report/arrow.png" alt />
        </div>
      </div>
      <!-- 实操评估师 -->
      <div class="appraiser" v-if="titleInfo.isExam" @click="jumpDefaultEav">
        <div class="logo">
          <!-- <div class="a-img"> -->
          <!-- <img :src="titleInfo.avatarUrl" alt /> -->
          <van-image v-if="titleInfo.avatarUrl" fit="cover" :src="titleInfo.avatarUrl" />
          <van-image
            v-if="!titleInfo.avatarUrl"
            fit="cover"
            src="../../assets/defaultPortrait.png"
          />
          <!-- </div> -->
          <div class="a-name">{{titleInfo.evaPersonName}}</div>
        </div>
        <div class="a-title-img">
          <div class="a-title" v-if="titleInfo.levelId == 1">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 初级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 2">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 中级</div>
          </div>
          <div class="a-title" v-if="titleInfo.levelId == 3">
            <img
              src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation_intermediate.png"
              alt
            />
            <div class="a-name">商用车鉴定评估师 高级</div>
          </div>
          <div class="logo-tips">示例</div>
        </div>
      </div>
      <!-- 评估内容 -->
      <ul class="changeNumber car-name">
        <li>
          <div>{{msgInfo.carAge}}</div>
          <div>车龄</div>
        </li>
        <li class="lines"></li>
        <li>
          <div>{{msgInfo.fuelType}}</div>
          <div>燃料</div>
        </li>
        <li class="lines"></li>
        <li>
          <div>{{msgInfo.emissionStd}}</div>
          <div>排放</div>
        </li>
      </ul>
      <div class="appInfo">
        <ul class="app-list">
          <li class="app-item">
            <div>品牌</div>
            <div v-if="msgInfo.brand">{{msgInfo.brand}}</div>
            <div v-if="!msgInfo.brand">-</div>
          </li>
          <li class="app-item">
            <div>型号</div>
            <div v-if="msgInfo.vehicleModel">{{msgInfo.vehicleModel}}</div>
            <div v-if="!msgInfo.vehicleModel">-</div>
          </li>
          <li class="app-item">
            <div style="width:80px;line-height:16px;margin-right:20px">发动机号码/电池组编号</div>
            <div v-if="msgInfo.engineNo">{{msgInfo.engineNo}}</div>
            <div v-if="!msgInfo.engineNo">-</div>
          </li>
          <li class="app-item">
            <div>发动机型号</div>
            <div v-if="msgInfo.engineModel">{{msgInfo.engineModel}}</div>
            <div v-if="!msgInfo.engineModel">-</div>
          </li>
          <li class="app-item">
            <div>VIN码</div>
            <div v-if="msgInfo.vin">{{msgInfo.vin}}</div>
            <div v-if="!msgInfo.vin">-</div>
            <div @click="lookVinInfo" class="lookVinInfo">查看</div>
          </li>
          <li class="app-item">
            <div>表征里程(km)</div>
            <div v-if="msgInfo.mileage">{{msgInfo.mileage}}</div>
            <div v-if="!msgInfo.mileage">-</div>
          </li>
          <li class="app-item">
            <div>推定里程(km)</div>
            <div class="app-item-img">
              <div v-if="msgInfo.calculationMileage">{{msgInfo.calculationMileage}}</div>
              <div v-if="!msgInfo.calculationMileage">-</div>
              <div v-if="msgInfo.calculationMileage  && msgInfo.mileage">
                <img
                  v-if="msgInfo.calculationMileage !== msgInfo.mileage"
                  src="../../assets/report/crazy.png"
                  alt
                  @click="numTime=true"
                />
              </div>
            </div>
          </li>
          <li class="app-item">
            <div>注册登记日期</div>
            <div v-if="msgInfo.registerDate">{{msgInfo.registerDate}}</div>
            <div v-if="!msgInfo.registerDate">-</div>
          </li>
          <li class="app-item">
            <div>年审检查日期</div>
            <div
              v-if="msgInfo.yearlyInspectionDue"
            >{{msgInfo.yearlyInspectionDue | dateFormatMonths}}</div>
            <div v-if="!msgInfo.yearlyInspectionDue">-</div>
          </li>
          <li class="app-item">
            <div>交强险截止日期</div>
            <div
              v-if="msgInfo.compulsoryTrafficInsuranceDue"
            >{{msgInfo.compulsoryTrafficInsuranceDue | dateFormatMonths}}</div>
            <div v-if="!msgInfo.compulsoryTrafficInsuranceDue">-</div>
          </li>
          <li class="app-item">
            <div>车船税截止日期</div>
            <div
              v-if="msgInfo.vehicleVesselTaxDue"
            >{{msgInfo.vehicleVesselTaxDue | dateFormatMonths}}</div>
            <div v-if="!msgInfo.vehicleVesselTaxDue">-</div>
          </li>
          <li class="app-item app-time">
            <div>第三方责任险截止日期</div>
            <div v-if="msgInfo.thirdInsuranceDue">{{msgInfo.thirdInsuranceDue | dateFormatMonths}}</div>
            <div v-if="!msgInfo.thirdInsuranceDue">-</div>
          </li>
          <li class="app-item app-time">
            <div>未接受处理的交通违法记录(条)</div>
            <div v-if="msgInfo.violation">{{msgInfo.violation | dateFormatMonths}}</div>
            <div v-if="!msgInfo.violation">-</div>
          </li>
          <li class="app-item app-time">
            <div>其他重要配置</div>
          </li>
          <li class="app-tip" v-if="msgInfo.otherConfig">{{msgInfo.otherConfig}}</li>
        </ul>
        <div class="lookMsg" @click="jumpInfo">查看更多基本信息</div>
      </div>
      <!-- 车辆图片 -->
      <div class="carImg">
        <div class="carImgTitle">车辆相关图片</div>
        <div class="cag-c">
          <div class="imgSwiper" v-for="(item,index) in list" :key="index">
            <ul class="carimg-nav" @click="lookAlls(index,0)">
              <li>{{item.title}}</li>
              <li class="ca-more">
                查看更多
                <van-icon name="arrow" />
              </li>
            </ul>
            <ul ref="imgList" class="carI-list">
              <li v-for="(ite,ind) in item.imgList" :key="ind">
                <!-- <img :src="ite" alt @click="lookAlls(index,ind)" /> -->
                <van-image fit="cover" :src="ite.value" @click="lookAlls(index,ind)" />
                <div class="imgName">{{ite.name}}</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 车辆报告 -->
      <div class="car-report">
        <div class="carImgTitle c-p">车辆技术鉴定报告</div>
        <ul class="changeNumber car-name c-p-n">
          <li>
            <div>{{reportInfo.score}}</div>
            <div style="font-size:#c9c9c9">综合评价分值</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{reportInfo.level}}</div>
            <div style="font-size:#c9c9c9">技术状态等级</div>
          </li>
          <li class="lines"></li>
          <li>
            <div>{{reportInfo.rate}}</div>
            <div style="font-size:#c9c9c9">技术成新率</div>
          </li>
        </ul>
        <ul class="report-list">
          <li class="report-list-item">
            <div class="report-name">鉴定项目</div>
            <div class="report-number">得分/满分</div>
            <div class="report-level">等级</div>
            <div class="report-bug">缺陷</div>
          </li>
          <li
            @click="jumpReport(index)"
            class="report-list-item"
            v-for="(item,index) in reportInfo.checkItems"
            :key="index"
          >
            <div class="report-name">{{item.shortName}}</div>
            <div class="report-number" v-if="item.score">{{item.score}}/{{item.total}}</div>
            <div class="report-number" v-if="item.coefficient">{{item.coefficient}}/{{item.total}}</div>
            <div class="report-number" v-if="item.total == 0">{{item.score}}/{{item.total}}</div>
            <div class="report-level">{{item.level}}</div>
            <div class="report-bug">
              <div v-if="item.count" class="error">{{item.count}}</div>
              <div v-if="!item.count" class="success">{{item.count}}</div>
              <van-icon name="arrow" />
            </div>
          </li>
        </ul>
        <div class="lookMsg look-report" @click="jumpReport('无')">查看详细报告</div>
      </div>
    </div>
    <!-- 实体减值项描述 -->
    <div class="impairmentItem">
      <div class="carImgTitle c-p">实体减值项描述</div>
      <div class="im-nav">
        <div class="im-item im-items">
          <div>1.{{impairment[0].title}}</div>
          <div>{{impairment[0].count}}处缺陷,小计减值￥{{formatCurrency(impairment[0].totalMoney)}}</div>
        </div>
      </div>
      <ul @click="jumpImpairment(0)" v-if="impairment[0].children">
        <li v-for="(item,index) in impairment[0].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{index+1}}) 减值项</div>
            <div>{{item.label}}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{formatCurrency(item.money)}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div class="im-img-item" v-for="(ite,ind) in item.images" :key="ind">
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>2.{{impairment[1].title}}</div>
          <div>{{impairment[1].count}}处缺陷,小计减值￥{{formatCurrency(impairment[1].totalMoney)}}</div>
        </div>
      </div>
      <ul @click="jumpImpairment(1)" v-if="impairment[1].children">
        <li v-for="(item,index) in impairment[1].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{index+1}}) 减值项</div>
            <div>{{item.label}}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{formatCurrency(item.money)}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div class="im-img-item" v-for="(ite,ind) in item.images" :key="ind">
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>3.{{impairment[2].title}}</div>
          <div>{{impairment[2].count}}处缺陷,小计减值￥{{formatCurrency(impairment[2].totalMoney)}}</div>
        </div>
      </div>
      <ul @click="jumpImpairment(2)" v-if="impairment[2].children">
        <li v-for="(item,index) in impairment[2].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{index+1}}) 减值项</div>
            <div>{{item.label}}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{formatCurrency(item.money)}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div class="im-img-item" v-for="(ite,ind) in item.images" :key="ind">
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>4.{{impairment[3].title}}</div>
          <div>{{impairment[3].count}}处缺陷,小计减值￥{{formatCurrency(impairment[3].totalMoney)}}</div>
        </div>
      </div>
      <ul @click="jumpImpairment(3)" v-if="impairment[3].children">
        <li v-for="(item,index) in impairment[3].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{index+1}}) 减值项</div>
            <div>{{item.label}}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{formatCurrency(item.money)}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div class="im-img-item" v-for="(ite,ind) in item.images" :key="ind">
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="im-nav">
        <div class="im-item">
          <div>5.{{impairment[4].title}}</div>
          <div>{{impairment[4].count}}处缺陷,小计减值￥{{formatCurrency(impairment[4].totalMoney)}}</div>
        </div>
      </div>
      <ul @click="jumpImpairment(4)" v-if="impairment[4].children">
        <li v-for="(item,index) in impairment[4].children" :key="index">
          <div class="im-item im-items im-title">
            <div>({{index+1}}) 减值项</div>
            <div>{{item.label}}</div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-msg">
              <div>缺陷描述</div>
              <div>{{item.desc}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-item im-items im-mark im-top">
              <div>减值价值(元)</div>
              <div>{{formatCurrency(item.money)}}</div>
            </div>
          </div>
          <div class="im-nav">
            <div class="im-img">
              <div class="im-img-item" v-for="(ite,ind) in item.images" :key="ind">
                <van-image fit="cover" :src="ite" />
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="lookMsg look-report" v-if="impairmentStatus" @click="jumpImpairment(8)">查看实体性减值</div>
    </div>
    <!-- 特殊事项说明 -->
    <div class="specialTip">
      <div class="carImgTitle c-p">特殊事项说明</div>
      <div class="sp-tips" v-if="titleInfo.specialDesc">{{titleInfo.specialDesc}}</div>
      <div class="sp-tips" v-if="!titleInfo.specialDesc">-</div>
    </div>
    <!-- 弹窗 -->
    <div class="dayDia">
      <van-popup v-model="showTime" closeable>
        <div class="showTimeTitle">评估基准日</div>
        <div class="showTimeTip">本鉴定评估结果可以作为报价参考依据。本项鉴定评估结论有效期为30天，自鉴定评估基准日至4月3日止。</div>
      </van-popup>
    </div>
    <div class="serDia">
      <van-popup v-model="showSeries" closeable>
        <div class="showTimeTitle">调整系数</div>
        <ul class="showSeries">
          <li class="showSeriesT">区域市场影响：</li>
          <li class="showSeriesTe">主要是指车辆在某区域保有量、环保要求、营销宣传、服务保障能力等形成的口碑，对二手商用车成交价格产生影响。</li>
          <li class="showSeriesT">品牌影响：</li>
          <li class="showSeriesTe">品牌也代表了车辆技术水平、制造水平、服务承诺等，从而影响二手车的保值程度。</li>
          <li class="showSeriesT">使用状况影响：</li>
          <li class="showSeriesTe">包括使用状况和使用环境工况。</li>
          <li class="showSeriesD showSeriesTd">
            <span>使用状况：</span>
            <span>里程、营运方式等影响；</span>
          </li>
          <li class="showSeriesD">
            <span>使用工况：</span>
            <span>道路条件、环境、载重等影响。</span>
          </li>
        </ul>
      </van-popup>
    </div>
    <div class="numDia">
      <van-popup v-model="numTime" closeable>
        <div class="showTimeTitle">推定里程</div>
        <div class="showTimeTip Ntips">差异原因人为调表</div>
      </van-popup>
    </div>
    <van-popup v-model="vinShow" closeable position="bottom" :style="{ height: '40%' }">
      <div class="vinData">
        <div class="vinDataTitle">查看车辆识别代号</div>
        <ul class="info_ul" v-if="!vinContentMessage">
          <li v-if="msgInfo.vin">{{msgInfo.vin.substr(0,8)}}</li>
          <li class="enterTxt">
            <input type="text" ref="vins" v-model="vinInfo" maxlength="1" />
          </li>
          <li class="enterTxt">
            <input type="text" ref="vin" v-model="vinInfos" maxlength="1" />
          </li>
          <li v-if="msgInfo.vin">{{msgInfo.vin.substr(10)}}</li>
        </ul>
        <div class="vinTips" v-if="!vinContentMessage">请输入9-10位的内容（数字/大写字母）</div>
        <div v-if="!vinContentMessage" class="submitVin" @click="submitVin">提交</div>
        <div v-if="vinContentMessage" id="copyInner" class="vinMessage">{{vinContentMessage}}</div>
        <div v-if="vinContentMessage" class="submitVin" @click="myCopy">复制</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  report,
  reportImg,
  reportInfo,
  getTechReport,
  getImpairment,
  getTaskVin,
} from "@/utils/http.js";
import { Toast } from "vant";

export default {
  name: "",
  components: {},
  props: [],
  data() {
    return {
      vinContentMessage: "",
      vinInfo: "",
      vinInfos: "",
      vinShow: false,
      numTime: false,
      // 当前页码
      current: 0,
      // 评估基准日展示
      showTime: false,
      // 车系展示
      showSeries: false,
      imgList: [],
      mechanism: false, //评估机构信息
      list: [],
      titleInfo: "",
      msgInfo: "",
      reportInfo: "",
      impairment: [
        { title: "驾驶室", count: "0", totalMoney: "0", children: [] },
        { title: "发动机", count: "0", totalMoney: "0", children: [] },
        { title: "底盘", count: "0", totalMoney: "0", children: [] },
        { title: "上装", count: "0", totalMoney: "0", children: [] },
        { title: "其他减值项", count: "0", totalMoney: "0", children: [] },
      ],
      impairmentStatus: false,
    };
  },
  computed: {},
  watch: {
    vinInfo(oldVal, newVal) {
      console.log(newVal);
      console.log(oldVal);
      if (oldVal) {
        this.$nextTick(() => {
          this.$refs.vin.focus();
        });
      }
    },
  },
  created() {
    document.title = "商用车鉴定评估报告预览";
  },
  mounted() {
    this.$nextTick(() => {
      this._loopImg();
    });
    let id = this.$route.query.id;
    // 图片
    this.getImgList(id);
    // 顶部
    this.getList(id);
    // 基本信息
    this.getInfo(id);
    // 报告
    this.getReportInfo(id);
    // 减值项
    this.getImpairmentInfo(id);
  },
  methods: {
    myCopy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("copyInner"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      console.log(document.execCommand("copy"));
      if (document.execCommand("copy")) {
        Toast("复制成功");
      }
    },
    async submitVin() {
      if (!this.vinInfo && !this.vinInfos) {
        Toast("请输入内容");
      }
      let str = this.vinInfo + this.vinInfos;
      let params = {
        taskId: this.$route.query.id,
        checkStr: str,
      };
      const { data: res } = await getTaskVin(params);
      console.log(res);
      if (res.resultStates != 0) {
        Toast(res.message);
        return;
      }
      this.vinContentMessage = res.content.vin;
    },
    // 获取上半部分数据
    async getList(id) {
      const { data: res } = await report({ taskId: id });
      // console.log(res);
      this.titleInfo = res.content;
      console.log(this.titleInfo.isExam, "=============");
      // this.titleInfo.isExam=true
    },
    // 获取基本评估数据
    async getInfo(id) {
      const { data: res } = await reportInfo({ taskId: id });
      // console.log(res);
      this.msgInfo = res.content;
    },
    // 获取图片
    async getImgList(id) {
      const { data: res } = await reportImg({ taskId: id });
      // console.log(res);
      res.content.forEach((item) => {
        item.imgList.forEach((items) => {
          this.imgList.push(items);
        });
      });
      this.list = res.content;
      this.$store.commit("setImg", res.content);
    },
    // 获取报告
    async getReportInfo(id) {
      const { data: res } = await getTechReport({ taskId: id });
      // console.log(res);
      this.reportInfo = res.content;
      this.$store.commit("setReport", res.content);
    },
    async getImpairmentInfo(id) {
      const { data: res } = await getImpairment({ taskId: id });
      if (res.content) {
        this.impairment = res.content;
        this.$store.commit("setImpairment", res.content);
        this.impairmentStatus = true;
      }
      // console.log(res);
    },
    // 轮播图事件
    onChange(index) {
      this.current = index;
    },
    // 查看基本信息页面
    jumpInfo() {
      this.$router.push({
        path: `/reportInfo`,
        query: {
          id: this.$route.query.id,
        },
      });
    },
    // 查看详细报告
    jumpReport(id) {
      // console.log(id);
      if (id !== "无") {
        this.$router.push({
          path: "/reportMessage",
          query: { id: id },
        });
      } else {
        this.$router.push("/reportMessage");
      }

      //
    },
    // 动态计算宽度
    _loopImg() {
      // let tabsWidth = 164;
      // let width = this.imgList.length * tabsWidth;
      // this.$refs.imgList[0].style.minwidth = `${width}px`;
    },
    // lookAll() {
    //   this.$router.push("/swiper");
    // },
    lookAlls(id, key) {
      let data = { id, key: key + 1 };
      this.$store.commit("setImgKey", data);
      this.$router.push("/swiper");
    },
    jumpImpairment(id) {
      // this.$router.push("/impairment");
      this.$router.push({
        path: "/impairment",
        query: {
          id,
        },
      });
    },
    jumpEav() {
      this.$router.push({
        path: "/eva",
        query: {
          certId: this.titleInfo.evaPersonCertId,
        },
      });
    },
    jumpDefaultEav() {
      this.$router.push("/defaultEva");
    },
    lookVinInfo() {
      this.vinShow = true;
      this.vinInfo = "";
      this.vinInfos = "";
      this.vinContentMessage = "";
      this.$nextTick(() => {
        this.$refs.vins.focus();
      });
    },
  },
};
</script>
<style lang='less' scoped>
.container {
  background: #f8f8f8;
  font-family: PingFangSC-Medium, PingFang SC;
  padding-bottom: 20px;
}
.img {
  width: 100vw;
  height: 211px;
  background: #ffdfdf;
}
.img > .van-image {
  width: 100vw;
  height: 100%;
}
.custom-indicator {
  position: absolute;
  width: 42px;
  height: 16px;
  right: 15px;
  bottom: 15px;
  font-size: 12px;
  text-align: center;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}
.title {
  width: 92%;
  height: 55px;
  background-image: url("../../assets/report/title.png");
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 10px;
}
.title-n {
  display: flex;
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
}
.reset {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 17px 17px 17px 0px;
  font-size: 9px;
  width: 130px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  margin-left: 6px;
}
.title-n > li:nth-of-type(1) {
  display: flex;
}
.timer {
  font-size: 12px;
  color: #c9c9c9;
}
.timer-t {
  width: 80px;
  text-align: center;
}
.day {
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 80px;
  justify-content: space-between;
}
.day > img {
  width: 12px;
  height: 12px;
}
.m-tip {
  font-size: 13px;
}
.m-num {
  font-size: 20px;
}
.m-let {
  font-size: 13px;
}
.carmsg {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 20px;
}
.carName {
  min-height: 48px;
  box-sizing: border-box;
  padding: 12px;
  font-size: 17px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 20px;
}
.series {
  height: 34px;
  background: linear-gradient(180deg, #f3f7ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  display: flex;
  align-items: center;
}
.series > div {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-left: 12px;
}
.series > img {
  width: 13px;
  height: 13px;
  margin-left: 5px;
}
.changeNumber {
  display: flex;
  // color: #666666;
  color: rgb(215 215 215);
  font-size: 13px;
  font-weight: 400;
  align-items: center;
  box-sizing: border-box;
  padding: 0 10px;
  margin-top: 10px;
  justify-content: space-around;
}
.changeNumber > li {
  width: 108px;
  text-align: center;
}
.changeNumber > li > div:nth-of-type(1) {
  font-size: 18px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 6px;
}
.changeNumber > li {
  width: 108px;
  text-align: center;
}
.changeNumber > .lines {
  width: 0px;
  height: 35px;
  border-left: 1px solid #e6e6e6;
}
.mechanism {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
  padding-bottom: 14px;
}
.m-title {
  height: 65px;
  background-image: url("../../assets/report/background.png");
  background-size: 100% 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  box-sizing: border-box;
  padding-top: 5px;
}
.m-title > div:nth-of-type(1) {
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 12px;
  font-weight: 500;
}
.m-title > div:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  height: 20px;
  line-height: 20px;
}
.m-content {
  /* display: flex; */
  // margin-top: 10px;
  margin-left: 12px;
  padding-top: 10px;
}
.m-content > li {
  display: flex;
  color: #333333;
  min-height: 30px;
  // align-items: center;
  font-size: 16px;
  margin: 10px 0;
}
.m-content > li > div:nth-of-type(1) {
  width: 82px;
  font-weight: 400;
}
.m-content > li > div:nth-of-type(2) {
  font-weight: 500;
  margin-left: 10px;
}
.m-tips {
  display: flex;
  justify-content: space-between;
}
.m-before {
  font-size: 16px;
  font-weight: 600;
  text-indent: 12px;
  margin-top: 12px;
}
.m-be-c {
  box-sizing: border-box;
  padding: 12px;
  font-size: 14px;
  line-height: 18px;
}
.m-be-c > span {
  font-weight: bold;
}
.m-be-tip {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-top: 10px;
  padding-bottom: 10px;
}
.m-be-tip > li {
  min-height: 20px;
  line-height: 22px;
  box-sizing: border-box;
  padding: 0 10px;
}
.showMechanism {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 12px;
}
.showMechanism > span {
  margin-right: 4px;
}
.appraiser {
  width: 96%;
  height: 50px;
  background-image: url("../../assets/report/people.png");
  background-size: 100% 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 10px;
}
.logo {
  display: flex;
  align-items: center;
}

.logo > .van-image {
  width: 26px;
  height: 26px;
  // border-radius: 50%;
  margin-right: 10px;
  // border: 1px solid #5ea7ff;
}
/deep/ .logo > .van-image > img {
  border-radius: 50%;
  // margin-right: 10px;
  border: 1px solid #5ea7ff;
}
.a-name {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}
.a-title-img {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 12px;
}
.a-title {
  display: flex;
}
.a-title > img {
  width: 13px;
  height: 11px;
  margin-right: 4px;
  margin-top: 2px;
}
.a-link {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  text-align: center;
}
.car-name {
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  /* padding: 0; */
  height: 77px;
  width: 96%;
  margin: 0 auto;
  margin-top: 10px;
  border-bottom: 1px solid #f5f5f5;
}
.appInfo {
  width: 96%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.app-list {
  box-sizing: border-box;
  padding: 15px;
}
.app-item {
  display: flex;
  font-size: 13px;
  color: #666666;
  align-items: center;
  height: 30px;
}
.app-item > div:nth-of-type(1) {
  width: 95px;
  margin-right: 4px;
}
.app-item > div:nth-of-type(2) {
  font-size: 14px;
  color: #333333;
}
.app-time > div:nth-of-type(1) {
  width: 180px;
}
.app-tip {
  font-size: 14px;
  line-height: 20px;
  /* margin-top: 10px; */
}
.lookMsg {
  width: 230px;
  height: 36px;
  background: #f4f8ff;
  line-height: 36px;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  color: #333333;
  margin-bottom: 10px;
}
.showTimeTitle {
  font-size: 19px;
  text-align: center;
  padding-top: 12px;
  line-height: 30px;
}
.showTimeTip {
  width: 95%;
  margin: 0 auto;
  font-size: 12px;
  word-break: break-all;
  word-wrap: break-word;
  margin-top: 5px;
  line-height: 18px;
}
.showSeries {
  width: 95%;
  margin: 0 auto;
}

.carImgTitle {
  width: 95%;
  margin: 0 auto;
  height: 48px;
  background: linear-gradient(180deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 7px 7px 0px 0px;
  line-height: 48px;
  text-indent: 12px;
  margin-top: 10px;
  font-size: 19px;
}
.showSeries {
  margin-top: 10px;
}
.showSeriesT {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
  margin: 8px 0;
}
.showSeriesTe {
  font-size: 10px;
  line-height: 12px;
}
/* .showSeriesTd {
  margin-top: 5px;
} */
.showSeriesD {
  margin: 5px 0;
  font-size: 10px;
}
.showSeriesD > span:nth-of-type(1) {
  font-weight: 500;
}
.cag-c {
  background: #ffffff;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
}
.carimg-nav {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 12px;
  color: #333333;
  font-size: 17px;
}
.ca-more {
  font-size: 14px;
  color: #666666;
}
.carI-list {
  height: 105px;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
}
.carI-list::-webkit-scrollbar {
  display: none;
}
.carI-list > li {
  width: 142px;
  height: 106px;
  margin-left: 10px;
  position: relative;
}
.carI-list > li:nth-of-type(1) {
  margin-left: 12px;
}
.carI-list > li:last-child {
  margin-right: 12px;
}
.carI-list > li > .van-image {
  width: 142px;
  height: 106px;
}
/deep/ .carI-list > li > .van-image > img {
  border-radius: 3px;
}
.imgName {
  width: 100%;
  height: 23px;
  position: absolute;
  bottom: 0;
  text-align: center;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 23px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 10px;
  // background: linear-gradient(180deg, rgba(238, 238, 238, 0) 0%, #000000 100%);
  border-radius: 0px 0px 3px 3px;
  background-image: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/gradient_background.png");
  background-size: 100% 100%;
}
.car-report {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.c-p {
  width: 100%;
}
.c-p-n {
  /* width: 100%; */
  background-image: url("../../assets/report/number.png");
  background-size: 100% 100%;
}
.c-p-n > li > div:nth-of-type(1) {
  color: #ffffff;
  margin-bottom: 10px;
}
.c-p-n > .lines {
  border-left: 1px solid #505050;
}
.report-list {
  box-sizing: border-box;
  padding: 0 12px;
  /* margin-top: 13px; */
}
.report-list-item:nth-of-type(1) {
  font-size: 16px;
  height: 50px;
}
.report-list-item:nth-of-type(1) > .report-bug {
  display: block;
}
.report-list-item > div:nth-of-type(1) {
  font-size: 16px;
}
.report-list-item {
  display: flex;
  min-height: 32px;
  justify-content: space-between;
  align-items: center;
}
.report-name {
  width: 110px;
  line-height: 20px;
}
.report-number,
.report-bug {
  width: 75px;
  font-size: 14px;
}
.report-level {
  width: 40px;
  font-size: 14px;
}
.report-bug {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 10px;
}
.report-bug > div {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 16px;
  font-size: 10px;
  color: #ffffff;
}
.report-bug > .error {
  background: #f9702b;
}
.report-bug > .success {
  background: #5edb7b;
}
.look-report {
  margin-top: 10px;
}
.impairmentItem {
  width: 95%;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
}
.im-nav {
  box-sizing: border-box;
  padding: 0 12px;
}

.im-item {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-top: 1px solid #eeeeee;
  font-size: 15px;
}
.im-items {
  border: none;
}

.im-title {
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 0 12px;
}
.im-top {
  border-top: 1px solid #eeeeee;
}
.im-mark {
  padding: 0 12px;
  color: #666666;
  justify-content: space-between;
}
.im-mark > div:nth-of-type(1) {
  width: 130px;
}
.im-img {
  border-top: 1px solid #cdcdcd;
  display: flex;
  box-sizing: border-box;
  padding: 0 12px;
  height: 90px;
  align-items: center;
}
.im-img-item {
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
.im-img-item > .van-image {
  width: 100%;
  height: 100%;
}
/deep/ .im-img-item > .van-image > img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.im-msg {
  line-height: 15px;
  min-height: 41px;
}
.specialTip {
  width: 95%;
  margin: 0 auto;
}
.sp-tips {
  box-sizing: border-box;
  padding: 12px;
  background: #ffffff;
  min-height: 40px;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}
/deep/ .van-popup {
  border-radius: 5px;
}
/deep/ .dayDia > .van-popup {
  width: 250px;
  height: 120px;
}
/deep/ .dayDia > .van-popup__close-icon--top-right {
  top: 0px !important;
  right: 0;
}
/deep/ .numDia > .van-popup {
  width: 250px;
  height: 100px;
}
/deep/ .serDia > .van-popup {
  width: 250px;
  height: 260px;
}
.Ntips {
  font-size: 16px;
  margin-top: 14px;
  text-align: center;
}
.app-item-img {
  display: flex;
  align-items: center;
}
.app-item-img img {
  width: 14px;
  height: 14px;
  margin-left: 6px;
}
.logo-tips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0266e6;
}
.m-tips-content {
  width: 240px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 22px;
}
.im-item > div:nth-of-type(2) {
  width: 200px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 22px;
  text-align: right;
}
.lookVinInfo {
  height: 40px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0266e6;
  line-height: 40px;
  margin-left: 50px;
}
.vinDataTitle {
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  border: 1px solid #f3f3f3;
}
.info_ul {
  display: flex;
  margin-top: 40px;
  box-sizing: border-box;
  padding: 0 36px;
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  align-items: center;
}
.enterTxt {
  width: 40px;
  height: 40px;
  background: #edf5ff;
  border-radius: 4px;
  margin: 0 10px;
}
.enterTxt > input {
  width: 100%;
  height: 100%;
  background: #edf5ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 10px;
}
.vinTips {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin-top: 15px;
  text-align: center;
}
.submitVin {
  width: 240px;
  line-height: 40px;
  background: linear-gradient(214deg, #059ff4 0%, #0266e6 100%);
  box-shadow: 0px 2px 6px 0px #0266e6;
  border-radius: 5px;
  margin: 20px auto;
  font-size: 15px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
}
.vinMessage {
  font-size: 22px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #333333;
  text-align: center;
  margin: 50px 0;
}
</style>